import React from 'react';
import './../../styles/style.scss';

interface Props {
name: string;
logoUrl?: string;
className?: string;
}


interface State {

}


export default class Projekt extends React.Component<Props, State>{
    constructor(props){
    super(props);
    
    }

    render(){

    
            return(
                <div className={this.props.className + " projekt"}>
                  <img src={this.props.logoUrl}></img>
                   
                </div>
            )
        

       
    }
}