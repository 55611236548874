import React from 'react';
import './../../styles/style.scss';
import logo from './../../assets/logo.png';
interface Props {

}


interface State {

}


export default class TextContent extends React.Component<Props, State>{
    constructor(props){
    super(props);
    
    }

    render(){
        return(
            <div className="text_content">
             
            <div className="left_col">
            <h3>Ihre eigene Web App</h3>
             <p>Die kresse webagentur hilft ihnen dabei ihre Web Präsenz aufzubauen. Wir bauen optisch Ansprechende Landing Pages mit den neusten Web Development Technologien.  </p>
            </div>

            <div className="right_col">
            <h3>Steigern sie mit uns ihre Online Präsenz. </h3>

            <p>Wir helfen ihnen dabei im Internet gefunden zu werden und ihre Kunden zu erreichen. Gemeinsam mit ihnen arbeiten wir eine komplett individuelle digital Strategie aus.</p>
            </div>

            <div className="left_col">
            <h3>Über die Kresse Webagentur</h3>
<p>Die kresse webagentur ist ein Hamburger Startup Unternehmen mit spezalisierung auf Web-Entwicklung und Online Marketing. 

Unser Team besteht aus PHP und Javascript Entwicklern. Wir realisieren Web Projekte auf basis von Wordpress, oder verschiedenen Javascript Frameworks, wie z.B. React, bzw. React Native.
</p>
            </div>

            <div className="right_col">
            <h3>Steigern sie mit uns ihre Online Präsenz. </h3>

            <p>Wir helfen ihnen dabei im Internet gefunden zu werden und ihre Kunden zu erreichen. Gemeinsam mit ihnen arbeiten wir eine komplett individuelle digital Strategie aus.</p>
            </div>

          
            </div>
        )
    }
}