import React from 'react';
import './../../styles/style.scss';
import TextField from '@material-ui/core/TextField';


interface Props {

}


interface State {

}


export default class Kontakt extends React.Component<Props, State>{
    constructor(props){
    super(props);
    
    }

    render(){
        return(
            <div className="kontakt">
                <h3>Nehmen sie jetzt mit uns Kontakt auf!</h3>
                <form method="post" action="submit/submit_footer.php">
                    <div className="formInputWrapper">
                    <TextField  name="vorname" label="Vorname"></TextField>
                    <TextField name="nachname" label="Nachname"></TextField>
                    <TextField className="full_row" type="email" name="email" label="Email"></TextField>
                    <TextField multiline rows={4} className="full_row" type="message" name="messagee" label="Nachricht"></TextField>

                    </div>
                
                    <div className="form_footer">
                    <input type="submit" value="Senden"></input>
                    </div>
                </form>
            </div>
        )
    }
}