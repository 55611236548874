import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import Anfrage from './components/Anfrage/Anfrage';
import Projekte from './components/Projekte/Projekte';
import WhyReact from './components/React/WhyReact';
import Kontakt from './components/Kontakt/Kontakt';
import Footer from './components/Footer/Footer';
import TextContent from './components/TextContent/TextContet';
import Intro from './components/Intro/Into';
import TagManager from 'react-gtm-module' 
import { render } from '@testing-library/react';
import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
ReactPixel.init('440064627235512');

ReactPixel.pageView();

const tagManagerArgs = {
  gtmId: 'GTM-TH4SJV2'
}

TagManager.initialize(tagManagerArgs);


export default class App extends React.Component {





  render(){
    return(
      <div className="App">
      <Header></Header>
      <Intro></Intro>
      <Anfrage></Anfrage>
      <TextContent>
      
      </TextContent>
      <Projekte></Projekte>
      <WhyReact></WhyReact>
      <Kontakt></Kontakt>
      <Footer></Footer>
     </div>
    )
  }
}

