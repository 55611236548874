import React from 'react';
import './../../styles/style.scss';
import logo from './../../logo.svg';
interface Props {

}


interface State {

}


export default class WhyReact extends React.Component<Props, State>{
    constructor(props){
    super(props);
    
    }

    render(){
        return(
            <div id="info" className="why_react">
               
                <div className="left_wrapper">
                <img src={logo} className="react_logo"></img>
                </div>
                
                <div className="right_wrapper">
                    <h3 className="align-left"> Warum React</h3>
                    <p className="align-center">React ist ein modernes Javascript Framework welches Anwendung bei modernen Webanwendungen / Projekten findet. Von kleinen Single Page Applications bis hin zu großen Projekten, mit React ist alles möglich. </p>
                    <h3 className="align-left">Komponenten</h3>
                    <p className="align-center"> React erlaubt es die UI in verschiedene, unabhängige, wiederverwendbare Teile aufzubrechen. Dadurch ist React perfekt geeignet für Landingpages. Die Seiten, oder auch einzelene Elemente können kinderleicht duplliziert und verändert werden. </p>
                    <h3 className="align-left"> Wiederverwendbarkeit und Scaleability</h3>
                    <p className="align-center">React verwendet die JSX Syntax Erweiterung. JSX verbindet HTML und CSS in einem Javascript Code. Dadurch können UI Elemente in eigene Komponente zusammengefasst werden, die ohne großen Aufwand auf der Seite wiederverwendet werden können. Dadurch ist React perfekt für A/B Tests geeignet, da eine Landingpage einfach dupliziert, und im Style angepasst werden kann. Auch für die Weiterentwicklung von bestehenden Projekten ist React perfekt geeignet. </p>
                    <h3 className="align-left">Geschwindigkeit und SEO</h3>
                    <p className="align-center">Anders als bei herkömmlichen CMS Systemen, wie z.B Wordpress, lädt React nur genau den Code den sie für die Darstellung ihrer Website benötigen. Dadurch wird die Ladezeit sehr gering gehalten. All unsere Landingpages die auf React basieren erreichen einen Score von 90+ Punkten beim Google Page Speed Test, sowohl für Desktop als auch für Mobile Varianten. </p>

                
                </div>
            </div>
        )
    }
}