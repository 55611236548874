import React from 'react';
import './../../styles/style.scss';
import TextField from '@material-ui/core/TextField';
import Seperator from '../Seperator/Seperator';

import Mockup from './../../assets/mockup.png'

interface Props {

}


interface State {

}


export default class Intro extends React.Component<Props, State>{
    constructor(props){
    super(props);
    
    }

    render(){
        return(
            <div className="intro">
             
             <div className="intro_section_left">
                <div className="intro_section_header_wrapper">
                    <h2>Webdesign.</h2>
                    <h2>Webentwicklung.</h2>
                    <h2>Online Marketing.</h2>
                </div>
                <div className="intro_claim_wrapper">
                    <p>Steigern Sie mit uns die Sichtbarkeit Ihrer Online-Präsenz </p>
                </div>
                <div className="intro_button_wrapper">
                    <a href="/#info">Mehr erfahren</a>
                </div>
             </div>
             <div className="intro_section_right">
               <img className="mockup" src={Mockup}></img>
                 </div>




            </div>
        )
    }
}