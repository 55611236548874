import React from 'react';
import './../../styles/style.scss';
import Frage from './Frage'
import Input from './Input';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


/*
Icons
*/

import privateWebsite from './../../assets/personal_website.svg';
import companyWebsite from './../../assets/company_website.svg';
import webshop from './../../assets/shop_website.svg';
import questionMark from './../../assets/question_mark.svg';

import createWebsite from './../../assets/create_website.svg';
import updateWebsite from './../../assets/update_website.svg';

import yesIcon from './../../assets/yes.svg';
import noIcon from './../../assets/no.svg';

import agency from './../../assets/agentur.svg';
import self from './../../assets/self.svg';

import urgent from './../../assets/urgent.svg';
import month from './../../assets/month.svg';
import year from './../../assets/year.svg';


interface Props {

}


interface State {
    antworten: any;
    lastAntwort: string;
    step: number,
    vorname: string,
    nachname: string,
    email: string,
    message: string,
    validForm: boolean,
    formError: string,
}


export default class Anfrage extends React.Component<Props, State>{
    myRef: any;
    constructor(props){
    super(props);
    this.state = {
        antworten: [],
        lastAntwort: '',
        step: 1,
        vorname: '',
        nachname: '',
        email: '',
        message: '',
        validForm: true,
        formError: '',
    }


    
    }


    executeScroll = () => this.myRef.scrollIntoView()


    handleClick(name){

        return new Promise((resolve,reject) => {
            this.setState({
                antworten:[...this.state.antworten, name],
                lastAntwort: name,
                step: this.state.step + 1,

            })
            resolve(name);
        })
    }

    handleChange(event){
       

        const target = event.target;
        const name = target.name;
           //@ts-ignore
            this.setState({
                [name]: target.value
            })
       
           
    }

    handleSubmit(){

        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

        if(!expression.test(String(this.state.email).toLowerCase())){
          this.setState({
              validForm: false,
              formError: 'Es gab einen Fehler bei den Eingaben.'
          })
          return;
        }

        let state = this.state;
        
        fetch('https://react.kresse-webagentur.de/submit/submit.php', {
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(state)
}).then(response => response.json()).then(responseData => {
   

   
   
   
    if(responseData.status === 'success') {
        this.setState({
            step: 9
           
        })
        this.executeScroll();
    }
    if(responseData.status === 'error') {
        this.setState({
            step: 10
        })
    }
})
    }

    render(){

        if(this.state.step === 1){
            return(
                
                <div className="anfrage">
                      <h2>Fordern sie jetzt ihr Angebot für ihre eigene Website ein ein</h2>
                     <Frage name="Um welche Projektart handelt es sich ?">
                    <Input
                        onClick={() => this.handleClick("Private Website").then((name) => console.log(this.state))}
                        name="Private Website"
                        logoUrl={privateWebsite}>
                    </Input>
                    <Input
                        onClick={() => this.handleClick("Firmen Website").then(name => console.log(this.state))}
                        name="Firmen Website"
                        logoUrl={companyWebsite}>
                    </Input>
                    <Input
                        onClick={() => this.handleClick("Webshop").then(name => console.log(this.state))}
                        name="Webshop"
                        logoUrl={webshop}>
                    </Input>
                    <Input
                        className="questionMark"
                        onClick={() => this.handleClick("Weiß nicht").then(name => console.log(this.state))}
                        name="Weiß nicht"
                        logoUrl={questionMark}>
                    </Input>
                </Frage>
                </div>
            )
        }

        else if(this.state.step === 2){
                return(
                    <div className="anfrage">
                      <Frage name="Was soll gemacht werden ?"> 
                      <Input
                        onClick={() => this.handleClick("Website erstellen").then((name) => console.log(this.state))}
                        name="Website erstellen"
                        logoUrl={createWebsite}>
                        </Input>
                        <Input
                        onClick={() => this.handleClick("Website aktuallisieren").then((name) => console.log(this.state))}
                        name="Website aktuallisieren"
                        logoUrl={updateWebsite}>
                        </Input>
                        <Input
                        onClick={() => this.handleClick("Weiß nicht").then((name) => console.log(this.state))}
                        name="Weiß nicht"
                        logoUrl={questionMark}
                        className="questionMark">
                        </Input>
                      </Frage>
               </div>
                )
        }


        else if(this.state.step === 3){
            return(
                <div className="anfrage">
                  <Frage name="Gibt es bereits einen Server für die Seite ?"> 
                  <Input
                    onClick={() => this.handleClick("Ja").then((name) => console.log(this.state))}
                    name="Ja"
                    logoUrl={yesIcon}>
                    </Input>
                    <Input
                    onClick={() => this.handleClick("Nein").then((name) => console.log(this.state))}
                    name="Nein"
                    logoUrl={noIcon}>
                    </Input>
                    <Input
                    onClick={() => this.handleClick("Weiß nicht").then((name) => console.log(this.state))}
                    name="Weiß nicht"
                    logoUrl={questionMark}
                    className="questionMark">
                    </Input>
                  </Frage>
           </div>
            )
    }

    else if(this.state.step === 4){
        return(
            <div className="anfrage">
              <Frage name="Wer soll die Inhalte der Website pflegen ?"> 
              <Input
                onClick={() => this.handleClick("Agentur").then((name) => console.log(this.state))}
                name="Agentur"
                logoUrl={agency}>
                </Input>
                <Input
                onClick={() => this.handleClick("Eigenständig").then((name) => console.log(this.state))}
                name="Eigenständig"
                logoUrl={self}>
                </Input>
                <Input
                onClick={() => this.handleClick("Weiß nicht").then((name) => console.log(this.state))}
                name="Weiß nicht"
                logoUrl={questionMark}
                className="questionMark">
                </Input>
              </Frage>
       </div>
        )
}


else if(this.state.step === 5){
    return(
        <div className="anfrage">
          <Frage name="Besteht bereits ein Coroprate Design ?"> 
          <Input
            onClick={() => this.handleClick("Ja").then((name) => console.log(this.state))}
            name="Ja"
            logoUrl={yesIcon}>
            </Input>
            <Input
            onClick={() => this.handleClick("Nein").then((name) => console.log(this.state))}
            name="Nein"
            logoUrl={noIcon}>
            </Input>
            <Input
            onClick={() => this.handleClick("Weiß nicht").then((name) => console.log(this.state))}
            name="Weiß nicht"
            logoUrl={questionMark}
            className="questionMark">
            </Input>
          </Frage>
   </div>
    )
}


else if(this.state.step === 6){
    return(
        <div className="anfrage">
          <Frage name="Ist Hosting und Domain vorhanden ? "> 
          <Input
            onClick={() => this.handleClick("Ja").then((name) => console.log(this.state))}
            name="Ja"
            logoUrl={yesIcon}>
            </Input>
            <Input
            onClick={() => this.handleClick("Nein").then((name) => console.log(this.state))}
            name="Nein"
            logoUrl={noIcon}>
            </Input>
            <Input
            onClick={() => this.handleClick("Weiß nicht").then((name) => console.log(this.state))}
            name="Weiß nicht"
            logoUrl={questionMark}
            className="questionMark">
            </Input>
          </Frage>
   </div>
    )
}


else if(this.state.step === 7){
    return(
        <div className="anfrage">
          <Frage name="Wann soll die Maßnahmen abgeschlosse sein ? "> 
          <Input
            onClick={() => this.handleClick("ASAP").then((name) => console.log(this.state))}
            name="ASAP"
            logoUrl={urgent}>
            </Input>
            <Input
            onClick={() => this.handleClick("In 2 bis 6 Monaten").then((name) => console.log(this.state))}
            name="In 2 bis 6 Monaten"
            logoUrl={month}>
            </Input>
            <Input
            onClick={() => this.handleClick("Mehr als 6 Monate").then((name) => console.log(this.state))}
            name="Mehr als 6 Monate"
            logoUrl={year}>
            </Input>
            <Input
            onClick={() => this.handleClick("Weiß nicht").then((name) => console.log(this.state))}
            name="Weiß nicht"
            logoUrl={questionMark}
            className="questionMark">
            </Input>
          </Frage>
   </div>
    )
}

else if(this.state.step === 8){
    return(
        <div className="anfrage anfrage_form">

          
          <Frage name="Erzählen sie uns mehr über sich ? "> 
         
            <div className="anfrage_form_wrapper">

            <TextField type="text" className="vorname_input" name="vorname" label="Vorname" onChange={this.handleChange.bind(this)}></TextField>
            <TextField type="text" className="nachname_input" name="nachname" label="Nachname" onChange={this.handleChange.bind(this)}></TextField>
            <TextField type="email" className="email_input" name="email" label="E-Mail" onChange={this.handleChange.bind(this)}></TextField>
            <TextField className="nachricht" name="message" label="Nachricht"  multiline rows={6} onChange={this.handleChange.bind(this)}></TextField>

         
       

         <div className="anfrage_form_footer">
         <Button className="anfrage_submit_button" onClick={this.handleSubmit.bind(this)} color="secondary" >Senden</Button>
         </div>

            <div className={"form_error_div"}>
                {this.state.formError}
            </div>


            </div>


            </Frage>
   </div>
    )
}
else if (this.state.step === 9){
    //@ts-ignore
    window.dataLayer.push({
        event: 'form_submitted',
    })
    console.log("Event to GTA submitted");
    return(
        <div ref={ref => this.myRef=ref} className="anfrage anfrage_sucess">
        <h3>Vielen dank für ihre Anfrage</h3>
    </div>
    )
}
else if (this.state.step === 10){
   return(
    <div className="anfrage anfrage_sucess">
    <h3>Bei ihrer Anfrage ist ein Fehler aufgetreten, bitte versuchen sie es erneut</h3>
</div>
   )
}

        else {
            return(
                <div className="anfrage">
                        <h1>Danke für ihre Anfrage</h1>
               </div>
            )
        }


    }
}