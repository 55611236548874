import React from 'react';
import './../../styles/style.scss';

import xing from './../../assets/xing.svg';
import linkedin from './../../assets/linkedin.svg';
import facebook from './../../assets/facebook.svg'
import { link } from 'fs';

interface Props {

}


interface State {

}


export default class Footer extends React.Component<Props, State>{
    constructor(props){
    super(props);
    
    }

    render(){
        return(
            <div className="footer">
               <div className="footer_first_col footer_col">
               <h4>Copyright © 2021 kresse-webagentur.</h4>
               </div>

               <div className="social_footer footer_second_col footer_col">
              <a href="https://www.linkedin.com/company/kresse-webagentur-gmbh/"><img src={linkedin}></img></a>
              <a target="_blank" href="https://www.facebook.com/Kresse-webagentur-GmbH-103292508470515"><img src={facebook}></img></a>
              

               </div>

               <div className="footer_third_col footer_col">
               <div className="footer_menu">
                    <a target="_blank" href="https://kresse-webagentur.de/impressum">Impressum</a>
                    <a target="_blank" href="https://kresse-webagentur.de/datenschutz">Datenschutz</a>
                </div>
               </div>

               
            </div>
        )
    }
}