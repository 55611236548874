import React from 'react';
import './../../styles/style.scss';
import nexpert from './../../assets/nexpert.png'

interface Props {
name: string;
onClick?: any;
logoUrl?: string,
className?: string
}


interface State {

}


export default class Input extends React.Component<Props, State>{
    constructor(props){
    super(props);
    
    }

    render(){
        return(
            <div onClick={this.props.onClick} className={this.props.className + " input"}>
                <img src={this.props.logoUrl}></img>
                <h4 className="input_headline">{this.props.name}</h4>
            </div>
        )
    }
}