import React from 'react';
import './../../styles/style.scss';
import logo from './../../assets/logo.png';
interface Props {

}


interface State {

}


export default class Header extends React.Component<Props, State>{
    constructor(props){
    super(props);
    
    }

    render(){
        return(
            <div className="header">
             
               <div className="logo_wrapper">
               <img src={logo}></img>
               </div>

          
            </div>
        )
    }
}