import React from 'react';
import './../../styles/style.scss';

interface Props {
name: string;
}


interface State {

}


export default class Frage extends React.Component<Props, State>{
    constructor(props){
    super(props);
    
    }

    render(){
        return(
            <div className="frage">
                <h3>{this.props.name}</h3>
               <div className="input_outer_wrapper">
               {this.props.children}
               </div>
            </div>
        )
    }
}