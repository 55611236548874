import React from 'react';
import './../../styles/style.scss';
import Projekt from './Projekt';

import nexpert from './../../assets/nexpert.png';
import siegerads from './../../assets/siegerads.png';

import tradetransformers from './../../assets/tradetransformers.svg';
import gudjard from './../../assets/gudjard.svg';

interface Props {

}


interface State {

}


export default class Projekte extends React.Component<Props, State>{
    constructor(props){
    super(props);
    
    }

    render(){
        return(
            <div className="projekte">

                <h2>Unsere Kunden</h2>
               
               <div className="projekte_inner_wrapper">
               <Projekt className="sieger" logoUrl={nexpert} name="nexpert"></Projekt>
                <Projekt className="sieger" logoUrl={siegerads} name="siegerprojekt"></Projekt>
                <Projekt className="trade" logoUrl={tradetransformers} name="fabi"></Projekt>
                <Projekt className="gudjard" logoUrl={gudjard} name="fake"></Projekt>

               </div>
            </div>
        )
    }
}